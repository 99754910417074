import {
  type JobApplicant,
  JobApplicantStatus,
  type JobApplicantStatusHistory,
  type UserProfileWithRelations,
} from '@factoryfixinc/ats-interfaces';
import { useFFRestApi } from '@/composables/useApi';
import { useConversationProProfileStore } from './pro-profile.store';
import type { ProUserProfileWithRelations } from './pro-profile';
import type { JobApplicantWithJobTitle } from '@/core/shared/job/job.type';
import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';
import {
  ApplicationBulkActions,
  type ApplicationIndexWithJobApplicantSharedFields,
  type BulkChangeApplicationStatusResult,
} from '@/core/conversations/conversation-index/conversation-index.type';
import ProNotesService from '@/core/shared/pro-notes/pro-notes.service';
import { JobApplicantPersistence } from '@/core/shared/job-applicant/job-applicant.persistence';
import type { UserMessageStatus } from '../message/types/get-user-message-status.type';
import {
  getRejectedsErrors,
  getResolvedDataItems,
  promisePoolAllSettled,
} from '@/utils/promise.util';
import type { CustomJobApplicant } from '@/utils/export-xlsx-utils';
import { ProProfilePersistence } from './pro-profile.persistence';

export default class ProProfileService {
  private readonly jobApplicantPersistence = new JobApplicantPersistence();
  private persist = new ProProfilePersistence();
  private conversationProfileStore = useConversationProProfileStore();
  private proNoteService = new ProNotesService();

  public static findPrimaryApplication<T extends ApplicationIndexWithJobApplicantSharedFields>(
    applications: T[] | undefined,
  ): T | undefined {
    const conversationIndexJobIds = new ConversationIndexService().getConversationIndexJobIds();
    const filteredJobIdsSet = new Set(conversationIndexJobIds);
    const applicationIndexesFilteredBySelectedJobIds = applications?.filter(({ jobId }) => {
      if (filteredJobIdsSet.size > 0 && jobId) {
        return filteredJobIdsSet.has(jobId);
      }
      return true;
    });
    return applicationIndexesFilteredBySelectedJobIds?.sort(
      (app1, app2) => (app2.score || 0) - (app1.score || 0),
    )?.[0];
  }

  public async getProProfile(
    employerId: number,
    userProfileId: number,
  ): Promise<ProUserProfileWithRelations> {
    const filter = encodeURIComponent(
      JSON.stringify({
        include: [
          {
            relation: 'pro',
            scope: {
              include: [
                { relation: 'proTaxonomyIndustryMaps' },
                {
                  relation: 'proTaxonomyMachineMaps',
                  scope: {
                    include: [{ relation: 'proTaxonomyMachineMapTaxonomyBrandMaps' }],
                  },
                },
                { relation: 'proTaxonomyKnowledgeDisciplineMaps' },
              ],
            },
          },
          {
            relation: 'jobApplicants',
            scope: {
              include: [
                {
                  relation: 'job',
                  scope: {
                    include: [{ relation: 'jobTitle' }],
                  },
                },
              ],
              where: { status: { nin: ['Notified'] } },
            },
          },
          { relation: 'proEducationCertifications' },
          { relation: 'proHistoryEmployers' },
          { relation: 'proReferences' },
          {
            relation: 'proAchievementMaps',
            scope: { include: [{ relation: 'achievement' }] },
          },
        ],
      }),
    );

    const { data: userProfile, error } = await useFFRestApi(
      `/employer/${employerId}/pro/${userProfileId}?filter=${filter}`,
    )
      .get()
      .json<ProUserProfileWithRelations>();

    if (error?.value || !userProfile.value) {
      this.conversationProfileStore.clearSelectedPro();
      this.conversationProfileStore.clearSelectedProApplication();
      throw new Error(error.value);
    }

    this.conversationProfileStore.setSelectedPro(userProfile.value);
    const primaryApplication = this.getPrimaryApplication(userProfile.value);
    if (primaryApplication) {
      this.conversationProfileStore.selectJobApplication(primaryApplication.id);
    }

    this.proNoteService.fetchProNotes(userProfile.value.pro.id);
    return userProfile.value;
  }

  public async getProProfileById(
    userProfileId: number,
    employerId: number,
  ): Promise<UserProfileWithRelations<'pro'> | undefined> {
    const proProfile = await this.persist.getByIdWithRelations(userProfileId, employerId, ['pro']);
    return proProfile;
  }

  public async changeApplicationStatus(
    applicationId: JobApplicant['id'],
    newStatus: JobApplicantStatus,
    reason: string = '',
    isSilent: boolean = true,
  ): Promise<void> {
    await this.jobApplicantPersistence.update(
      applicationId,
      { status: newStatus, rejectionReason: reason },
      isSilent,
    );
    const newJobApplicant = await this.jobApplicantPersistence.get(applicationId);
    this.conversationProfileStore.patchApplicationStatus(applicationId, newJobApplicant);
  }

  public async bulkChangeApplicationStatus(
    applicationIds: number[] | undefined,
    action: ApplicationBulkActions,
    isSilent: boolean = true,
    reason: string = '',
  ): Promise<BulkChangeApplicationStatusResult | undefined> {
    if (!applicationIds) return;
    const newStatus = this.findStatusByBulkAction(action);

    const applicationsIds = applicationIds.map((id) => id);

    const poolLimit = 5;
    const result = await promisePoolAllSettled<number, void>(poolLimit, applicationsIds, (id) =>
      this.jobApplicantPersistence.update(
        id,
        { status: newStatus, rejectionReason: reason },
        isSilent,
      ),
    );

    const successCount = getResolvedDataItems(result).length;
    const errorCount = getRejectedsErrors(result).length;

    return { result, successCount, errorCount };
  }

  private findStatusByBulkAction(action: ApplicationBulkActions): JobApplicantStatus {
    switch (action) {
      case ApplicationBulkActions.MOVE_TO_REJECTED:
      case ApplicationBulkActions.MOVE_TO_REJECTED_W_REASON:
        return JobApplicantStatus.REJECTED;
      case ApplicationBulkActions.MOVE_TO_REVIEWED:
        return JobApplicantStatus.REVIEW;
      case ApplicationBulkActions.MOVE_TO_ENGAGED:
      default:
        return JobApplicantStatus.CLIENT;
    }
  }

  public async getApplicantsByApplicationId(
    applicationId: number,
    applicantIds: number[],
  ): Promise<CustomJobApplicant[] | undefined> {
    //We fetch only the selected applicants from the server
    const filter = `{"where": {"JobApplicant.id": {"inq": [${applicantIds}] } }}`;

    const jobApplicants = await this.jobApplicantPersistence.getApplicantsByJobId(
      applicationId,
      filter,
    );

    return jobApplicants;
  }

  public async getApplicantStatusHistory(
    applicationId?: number,
  ): Promise<JobApplicantStatusHistory[]> {
    if (applicationId === undefined) return [];
    const statusHistory =
      await this.jobApplicantPersistence.getApplicantsStatusHistory(applicationId);

    return statusHistory;
  }

  // this should select the application according to the rules of precedence.
  // initially this will be a simplified version and later based on score
  private getPrimaryApplication(
    userProfile: ProUserProfileWithRelations,
  ): JobApplicant | undefined {
    if (!userProfile) {
      return;
    }
    const { jobApplicants } = userProfile;

    return ProProfileService.findPrimaryApplication<JobApplicant>(jobApplicants);
  }

  public get selectedProProfile(): ProUserProfileWithRelations | null {
    return this.conversationProfileStore.selectedPro || null;
  }

  public get selectedProApplication(): JobApplicantWithJobTitle | undefined {
    return this.conversationProfileStore.selectedProApplication;
  }

  public get selectedProMessageStatus(): UserMessageStatus | undefined {
    return this.conversationProfileStore.selectedProMessageStatus;
  }

  public get isCopilotSourced(): boolean | undefined {
    return (
      this.selectedProApplication?.initiatedByOutreach &&
      this.selectedProApplication?.initiatedByCopilot
    );
  }

  public async downloadProfilePdf(applicationId: number): Promise<string> {
    const { data, error } = await useFFRestApi(`/job-applicant/${applicationId}/profile-pdf`)
      .get()
      .text();
    if (error?.value) {
      throw new Error(error.value);
    }
    return data.value || '';
  }
}
